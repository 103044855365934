import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as Icon } from '../assets/Scrybe_Logo.svg';
import { FaCanadianMapleLeaf, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import { FaRedditAlien } from 'react-icons/fa';
import { LuDot } from 'react-icons/lu';

const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
          {/* <div>
                          <h4 className="text-xs font-semibold text-white uppercase dark:text-white">Product</h4>
                          <div className="mt-3 grid space-y-3 text-sm">
                              <p><a className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap" href="#">Pricing</a></p>
                          </div>
                      </div> */}
        </div>
        <div className="pt-5 mt-5 border-t border-gray-200 dark:border-gray-700">
          <div className="flex flex-col items-center md:flex-row sm:justify-between sm:items-center gap-2">
            <nav className="flex gap-x-3 md::gap-x-4 text-sm text-center">
              <Link
                to="terms-of-service"
                className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap"
              >
                Terms
              </Link>
              <Link
                to="privacy-policy"
                className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap"
              >
                Privacy
              </Link>
              <Link
                to="/faq"
                className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap"
              >
                FAQ
              </Link>
              <Link
                to="/pricing"
                className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap"
              >
                Pricing
              </Link>
              <Link
                to="/credit"
                className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap"
              >
                Music Credit
              </Link>
              <Link
                to="/contact"
                className="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white whitespace-nowrap"
              >
                Contact
              </Link>
            </nav>
            <div className="flex gap-x-2 items-center text-sm">
              <div className="flex items-center gap-x-1">
                <div className="inline-flex whitespace-nowrap gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">
                  © 2024 Scrybe
                </div>
                <FaCanadianMapleLeaf className="w-3 h-3 text-white" />
              </div>
              <LuDot className="w-4 h-4 text-white" />
              <div className="flex gap-x-4">
                <a
                  className="inline-block text-white hover:text-white dark:hover:text-white"
                  href="https://twitter.com/ScrybeAI"
                >
                  <FaXTwitter className="w-4 h-4" />
                </a>
                <a
                  className="inline-block text-white hover:text-white dark:hover:text-white"
                  href="https://www.reddit.com/user/ScrybeSquid/"
                >
                  <FaRedditAlien className="w-4 h-4" />
                </a>
                <a
                  className="inline-block text-white hover:text-white dark:hover:text-white"
                  href="https://www.youtube.com/@ScrybeQuill"
                >
                  <FaYoutube className="w-4 h-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

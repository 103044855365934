function PrivacyPolicyPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Privacy Policy
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[15em] mx-auto">
          Last updated: March 10, 2024
        </p>
      </div>

      <div className="grid gap-4 md:gap-8">
        <div>
          <p className="mb-8 dark:text-gray-400 text-xl">
            Welcome to Scrybe, where your tabletop role-playing game (TTRPG)
            sessions are transcribed, summarized, and immortalized with cutting
            edge mortal technologies that rival arcane magic. Picture this: a
            quill that never runs dry, ink that flows as freely as the tales
            from your adventures, all working tirelessly to capture and recount
            your epic sagas.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Information Collected
          </h2>
          <p className="mb-5 dark:text-gray-400">
            In the quest to provide you with transcription and narration
            services, Scrybe collects a few key pieces of data:
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">Your email address</li>
            <li className="mb-2 dark:text-gray-400">
              Your associated display name
            </li>
            <li className="mb-2 dark:text-gray-400">
              Your account creation date
            </li>
            <li className="mb-2 dark:text-gray-400">
              Your uploaded session recordings (temporarily only)
            </li>
            <li className="mb-2 dark:text-gray-400">That's it!</li>
          </ul>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Payment Information
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe uses Stripe for payment processing. Payment details are
            directly provided to Stripe, and Scrybe does not store these
            details. Please refer to Stripe's privacy policy for information on
            their data handling practices.
          </p>

          {/* <h2 className="text-lg font-semibold mb-2 dark:text-white">Usage Information</h2>
                        <p className="mb-5 dark:text-gray-400">Information related to how the service is accessed and used (e.g., browser type, access times) may be collected to improve user experience.</p> */}

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Legal Compliance
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Your information may be used to comply with legal obligations,
            resolve disputes, and enforce agreements.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Data Sharing and Disclosure
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Personal information is not sold or rented to third parties. It may
            be shared with third-party services to the extent necessary for
            providing and improving the service, such as Google Cloud or
            Firebase.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            International Data Transfer
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Your personal data listed above may be transferred to — and
            maintained on — computers located outside of your state, province,
            country, or other governmental jurisdiction where the data
            protection laws may differ. Scrybe's primary operations are
            conducted in Canada, with cloud functions and file storage hosted in
            the United States.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Your Rights
          </h2>
          <p className="mb-5 dark:text-gray-400">
            You have the right to access, update, or delete your personal
            information at any time manually or by contacting Scrybe.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Data Retention
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe retains personal information only for as long as necessary to
            provide the service and fulfill the purposes outlined in this
            policy. Session recordings are retained solely for your convenience.
          </p>
          <p className="mb-5 dark:text-gray-400">
            All audio recordings uploaded to Scrybe are stored only as long as
            they are needed to generate the transcription, after which they are
            permanently deleted.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Security
          </h2>
          <p className="mb-5 dark:text-gray-400">
            The security of your data is important. While no online service can
            be 100% secure, Scrybe uses industry standard security measures to
            keep your data safe.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Changes to Privacy Policy
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe reserves the right to update or change this privacy policy at
            any time. You are advised to review this policy periodically for any
            changes.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Contact Us
          </h2>
          <p className="mb-5 dark:text-gray-400">
            For any questions or concerns regarding this privacy policy or the
            handling of personal data, please contact Scrybe at the contact
            information provided on the website.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Agreement
          </h2>
          <p className="mb-5 dark:text-gray-400">
            By using Scrybe, you acknowledge that you have read and agree to
            this privacy policy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;

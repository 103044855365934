import React from 'react';
import TextBox from './TextBox';

const AudioDemo = ({ video_source, text, title, reversed }) => {
  const imageSection = (
    <div className="flex flex-grow basis-2/5 md:max-w-sm mx-auto">
      <video
      // append #t=0.1 to the video source to prevent the video from showing the first frame as a placeholder
        src={video_source + '#t=0.1'}
        className="rounded-4xl shadow-xl w-full"
        controls
        preload="metadata"
        playsInline
        alt="Scrybe recap demo video."
      />
    </div>
  );

  const textBoxSection = (
    <div className="flex flex-grow basis-3/5 h-80 ">
      <TextBox title={title} content={text} />
    </div>
  );

  return (
    <div
      className={`mx-auto ${reversed ? 'flex flex-col-reverse' : 'flex flex-col'} md:flex-row md:items-center md:justify-center gap-8`}
    >
      {reversed ? textBoxSection : imageSection}
      {reversed ? imageSection : textBoxSection}
    </div>
  );
};

export default AudioDemo;

function TermsPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Terms of Service
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[15em] mx-auto">
          Last updated: January 13, 2024
        </p>
      </div>

      <div className="grid gap-4 md:gap-8">
        <div>
          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Acceptance of Terms
          </h2>
          <p className="mb-5 dark:text-gray-400">
            By accessing and using the website Scrybe (
            <a href="/">
              <strong>www.scrybe.ca</strong>
            </a>
            ), you agree to be bound by these Terms of Service ("Terms"). If you
            do not agree to these Terms, you are not permitted to use Scrybe.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Service Description
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe is an online tool designed for tabletop role-playing game
            (TTRPG) players to upload recordings of their game sessions. Scrybe
            transcribes the audio, generates a recap using artificial
            intelligence, and provides a narration of the recap through
            text-to-speech (TTS) technology.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            User Responsibilities
          </h2>

          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              You agree not to use Scrybe for any unlawful purposes or in a way
              that violates the rights of others.
            </li>
          </ul>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Intellectual Property Rights
          </h2>
          <p className="mb-5 dark:text-gray-400">
            You are responsible for any content you upload to Scrybe, including
            obtaining necessary permissions for any copyrighted material. This
            includes but is not limited to recordings uploaded and custom
            uploaded background music.
          </p>
          <p className="mb-5 dark:text-gray-400">
            If you publish your Scrybe-narrated recap elsewhere, you must
            attribute the original composer of the background music as detailed
            in the original composition's license. The required credit will be
            provided.
          </p>
          <p className="mb-5 dark:text-gray-400">
            Aside from the provided background music, any content generated by
            Scrybe in response to your input or based on your uploaded
            recordings is automatically owned by you, the user. You are granted
            full copyright ownership, subject to the terms and conditions
            outlined in this agreement. This ownership includes the exclusive
            right to use, reproduce, modify, publish, distribute, perform,
            display, and create derivative works from the Generated Content,
            both within and outside of Scrybe, for any purpose.
          </p>
          <p className="mb-5 dark:text-gray-400">
            Scrybe respects intellectual property rights and expects its users
            to do the same. Any infringement of intellectual property rights may
            result in the termination of your account.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Data Privacy
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Please refer to Scrybe's{' '}
            <a href="/privacy-policy">
              <strong>Privacy Policy</strong>
            </a>{' '}
            for information on how personal data is collected, used, and shared.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Limitation of Liability
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe will not be liable for any indirect, incidental, special,
            consequential, or punitive damages resulting from your use of the
            service.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Changes to the Terms
          </h2>
          <p className="mb-5 dark:text-gray-400">
            Scrybe reserves the right to modify these Terms at any time. You are
            advised to review the Terms periodically for any changes.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Governing Law
          </h2>
          <p className="mb-5 dark:text-gray-400">
            These Terms shall be governed by the laws of Canada. Any disputes
            arising under or in connection with these Terms shall be subject to
            the exclusive jurisdiction of the Canadian courts.
          </p>

          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            {' '}
            Contact Information
          </h2>
          <p className="mb-5 dark:text-gray-400">
            For any questions or concerns regarding these Terms, please contact
            us via the information provided on the{' '}
            <a href="/contact">
              <strong>Contact Page</strong>.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsPage;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrybeLogo from '../assets/Scrybe_Logo_New.svg';
import { ImQuill } from 'react-icons/im';

import { isSafari } from 'react-device-detect';

// Import video
import ScrybeLogoAnimation from '../assets/Scrybe_Logo_Animation.mp4';
import ScrybeVideoLoadingPlaceholder from '../assets/Scrybe_Logo_Hero_Video_Loading_Placeholder.png';

function Hero({ onButtonClick }) {
  return (
    // <!-- Hero -->
    <div className="my-[15%] md:my-0">
      {/* <!-- Grid --> */}
      <div className="grid md:grid-cols-2 md:items-center">
        <div className="flex flex-col justify-center items-center">
          {/* <!-- Title --> */}
          <div className="max-w-2xl text-center flex items-center justify-center">
            <h1 className="font-inknut text-5xl sm:text-7xl lg:text-8xl dark:text-gray-200">
              Scrybe
            </h1>
            <div className="w-[6rem] sm:w-[7rem] lg:w-[9rem] md:hidden relative">
              {isSafari ? (
                <img
                  className="w-full h-auto object-contain"
                  src={ScrybeLogo}
                  alt="Scrybe logo"
                />
              ) : (
                <video
                  className="w-full h-auto object-contain mix-blend-multiply"
                  src={ScrybeLogoAnimation}
                  autoPlay
                  muted
                  playsInline
                  disableRemotePlayback
                ></video>
              )}
              <img
                className="absolute top-0 left-0 w-full h-auto object-contain opacity-10 pointer-events-none"
                src={ScrybeVideoLoadingPlaceholder}
                alt="Scrybe logo"
              />
            </div>
          </div>
          {/* <!-- End Title --> */}

          <div className="mt-5 max-w-3xl text-center mx-auto">
            <h2 className="font-inknut text-3xl lg:text-4xl text-gray-600 dark:text-gray-400 max-w-md">
              Auto-Generate Epic Tabletop RPG Recaps
            </h2>
          </div>

          {/* <!-- Buttons --> */}
          <div className="mt-8 gap-3 flex justify-center">
            <button
              onClick={onButtonClick}
              className="inline-flex whitespace-nowrap justify-center items-center py-3 px-4 gap-x-3 text-center bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 dark:focus:ring-offset-gray-800"
            >
              View sample
              <svg
                className="flex-shrink-0 w-4 h-4"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <Link
              to="/pricing"
              className="py-3 px-4 flex items-center justify-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
            >
              Get started
            </Link>
          </div>
          {/* <!-- End Buttons --> */}
        </div>
        {/* <!-- End Col --> */}

        <div className="aspect-w-9 aspect-h-9 ms-4 hidden md:block h-full relative">
          {isSafari ? (
            <img
              className="w-full h-full object-cover"
              src={ScrybeLogo}
              alt="Scrybe logo"
            />
          ) : (
            <video
              className="w-full h-full object-cover"
              src={ScrybeLogoAnimation}
              autoPlay
              muted
              playsInline
              disableRemotePlayback
              style={{ imageRendering: 'auto' }}
            ></video>
          )}
          <img
            className="absolute top-0 left-0 w-full h-full object-cover opacity-10 pointer-events-none"
            src={ScrybeVideoLoadingPlaceholder}
            alt="Scrybe logo"
          />
        </div>
        {/* <!-- End Col --> */}
      </div>
      {/* <!-- End Grid --> */}
    </div>
  );
}

export default Hero;

import React, { useRef } from 'react';

import the_enchanted_scribe from '../assets/the_enchanted_scribe.webp';
import dm_timothy from '../assets/dm_timothy.jpg';
import likely_mimics from '../assets/likely_mimics.jpg';

// Import Splide and SplideSlide components
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Import Splide styles
import '@splidejs/splide/css';

const Testimonials = () => {
  const splideRef = useRef(null);

  const handlePrevClick = () => {
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.go('-1');
    }
  };

  const handleNextClick = () => {
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.go('+1');
    }
  };

  return (
    <div className="h-full flex flex-col gap-4">
      <Splide
        ref={splideRef}
        aria-label="Testimonials"
        options={{
          rewind: true,
          perPage: 3,
          perMove: 1,
          gap: '1.5rem',
          pagination: false,
          fixedHeight: '500px',
          arrows: false, // Disable default arrows
          breakpoints: {
            1024: {
              perPage: 1,
              fixedHeight: 'auto',
            },
          },
        }}
        className="h-full"
      >
        <SplideSlide className="h-full">
          <div className="flex flex-col bg-white rounded-xl dark:bg-neutral-900 border h-full box-border">
            <div className="flex flex-col justify-center flex-auto py-12 px-4 md:py-14 md:px-6 relative">
              <svg
                className="z-0 absolute top-0 start-0 transform translate-x-4 translate-y-2 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                className="z-0 absolute bottom-0 end-0 transform -translate-x-4 -translate-y-2 rotate-180 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <p className="relative text-base italic md:text-lg text-gray-800 dark:text-neutral-200 text-center">
                The potential of this tool to enhance the start of each session,
                prime my players beforehand, and save me so much time is
                incredible. This project will be a game-changer for many of us
                in the TTRPG community.
              </p>
              {/* <p className="relative text-base italic md:text-lg text-gray-800 dark:text-neutral-200 text-center">
                        I was so impressed with its ability to 'cut out the crap' when
                        creating the summary. My group and I often get sidetracked, like
                        really sidetracked, and this tool will help us stay focused.
                        Being both a player and a DM in 2 to 4 games a week, I know this
                        will make getting back into the game much easier.
                      </p> */}
            </div>
            <div className="p-4 bg-gray-100 rounded-b-xl md:px-7 dark:bg-neutral-800">
              <div className="flex items-center gap-x-3">
                <span className="w-8 h-8 sm:h-[2.875rem] sm:w-[2.875rem] inline-flex items-center justify-center size-[38px] rounded-full bg-white border border-gray-300 dark:bg-neutral-800 dark:border-neutral-700">
                  <span className=" font-medium text-sm text-gray-800 leading-none dark:text-neutral-200">
                    JJ
                  </span>
                </span>
                <div className="grow">
                  <p className="text-sm sm:text-base font-semibold text-gray-800 dark:text-neutral-200">
                    Jax J.
                  </p>
                  <p className="text-xs text-gray-500 dark:text-neutral-400">
                    Scrybe User
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide className="h-full">
          <div className="flex flex-col bg-white rounded-xl dark:bg-neutral-900 border h-full box-border">
            <div className="flex flex-col justify-center flex-auto py-12 px-4 md:py-14 md:px-6 relative">
              <svg
                className="z-0 absolute top-0 start-0 transform translate-x-4 translate-y-2 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                className="z-0 absolute bottom-0 end-0 transform -translate-x-4 -translate-y-2 rotate-180 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <p className="relative text-base italic md:text-lg text-gray-800 dark:text-neutral-200 text-center">
                The option to proofread the summary is awesome. Awesome,
                awesome, awesome. It allowed me to correct spellings and flesh
                out things I wanted narrated. This also allowed me to type in a
                few sentences to summarize the first 15 minutes of our game that
                I forgot to record!
              </p>
            </div>
            <div className="p-4 bg-gray-100 rounded-b-xl md:px-7 dark:bg-neutral-800">
              <div className="flex items-center gap-x-3">
                <a
                  className="shrink-0 w-8 h-8 sm:h-[2.875rem] sm:w-[2.875rem] rounded-full overflow-hidden"
                  href="https://www.youtube.com/@LikelyMimics"
                >
                  <img src={likely_mimics} alt="Likely Mimics Avatar" />
                </a>
                <div className="grow">
                  <p className="text-sm sm:text-base font-semibold text-gray-800 dark:text-neutral-200">
                    Scott M.
                  </p>
                  <p className="text-xs text-gray-500 dark:text-neutral-400 hover:underline">
                    <a
                      href="https://www.youtube.com/@LikelyMimics"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Likely Mimics
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide className="h-full">
          <div className="flex flex-col bg-white rounded-xl dark:bg-neutral-900 border h-full box-border">
            <div className="flex flex-col justify-center flex-auto py-12 px-4 md:py-14 md:px-6 relative">
              <svg
                className="z-0 absolute top-0 start-0 transform translate-x-4 translate-y-2 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                className="z-0 absolute bottom-0 end-0 transform -translate-x-4 -translate-y-2 rotate-180 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <p className="relative text-base italic md:text-lg text-gray-800 dark:text-neutral-200 text-center">
                If you've ever turned recaps over to your players before and
                felt like nobody was listening for the entire session, you can
                count on Scrybe to not only listen to the entire recording but
                to pick out the most important parts that need to be reminded to
                your players to get them going next week.
              </p>
            </div>
            <div className="p-4 bg-gray-100 rounded-b-xl md:px-7 dark:bg-neutral-800">
              <div className="flex items-center gap-x-3">
                <a
                  className="shrink-0 w-8 h-8 sm:h-[2.875rem] sm:w-[2.875rem] rounded-full overflow-hidden"
                  href="https://www.youtube.com/@DM-Timothy"
                >
                  <img
                    className="transform scale-110"
                    src={dm_timothy}
                    alt="DM Timothy Avatar"
                  />
                </a>
                <div className="grow">
                  <p className="text-sm sm:text-base font-semibold text-gray-800 dark:text-neutral-200">
                    Timothy Austen
                  </p>
                  <p className="text-xs text-gray-500 dark:text-neutral-400 hover:underline">
                    <a
                      href="https://www.youtube.com/@DM-Timothy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      DM Timothy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide className="h-full">
          <div className="flex flex-col bg-white rounded-xl dark:bg-neutral-900 border h-full box-border">
            <div className="flex flex-col justify-center flex-auto py-12 px-4 md:py-14 md:px-6 relative">
              <svg
                className="z-0 absolute top-0 start-0 transform translate-x-4 translate-y-2 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                className="z-0 absolute bottom-0 end-0 transform -translate-x-4 -translate-y-2 rotate-180 w-8 h-8 text-gray-200 md:h-10 md:w-10 dark:text-neutral-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <p className="relative text-base italic md:text-lg text-gray-800 dark:text-neutral-200 text-center">
                This innovation not only enhances the continuity and depth of
                campaigns but also allows GMs to devote more energy to
                creativity and player engagement.
              </p>
            </div>
            <div className="p-4 bg-gray-100 rounded-b-xl md:px-7 dark:bg-neutral-800">
              <div className="flex items-center gap-x-3">
                <a
                  className="shrink-0 w-8 h-8 sm:h-[2.875rem] sm:w-[2.875rem] rounded-full overflow-hidden"
                  href="https://www.the-enchanted-scribe.com/"
                >
                  <img
                    className="transform scale-110"
                    src={the_enchanted_scribe}
                    alt="The Enchanted Scribe Avatar"
                  />
                </a>
                <div className="grow">
                  <p className="text-sm sm:text-base font-semibold text-gray-800 dark:text-neutral-200">
                    Danny McKeever
                  </p>
                  <p className="text-xs text-gray-500 dark:text-neutral-400 hover:underline">
                    <a
                      href="https://www.the-enchanted-scribe.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Enchanted Scribe
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
      </Splide>
      {/* Custom navigation buttons */}
      <div className="w-full flex justify-center gap-4">
        <button
          onClick={handlePrevClick}
          className="py-2 px-3 flex items-center justify-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 min-w-[85px]"
        >
          {' '}
          <svg
            className="rotate-90 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
          Prev
        </button>

        <button
          onClick={handleNextClick}
          className="py-2 px-3 flex items-center justify-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 min-w-[85px]"
        >
          Next{' '}
          <svg
            className="-rotate-90 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
